/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent, ProgressBar, Spinner, Tag } from '@blueprintjs/core';

const DownLoadDataContainer = (props) => (
  <div className="DownloadData">
    {props.showDLButton &&
      (props.dataFileQuery.error ? (
        <span>Error getting Data file link</span>
      ) : (
        <a href={props.dataFileQuery.data}>
          <Button className="downloadFileButton" icon="download">
            Download Data File
          </Button>
        </a>
      ))}
    <div className="runDetails">
      {props.runDetailsFileQuery.isLoading ? (
        <Spinner size={20} />
      ) : (
        !props.dataFileQuery.isLoading && (
          <Tag icon={props.dataUpToDate ? 'updated' : 'outdated'} intent={props.dataUpToDate ? Intent.SUCCESS : Intent.WARNING} minimal round>
            {props.dataUpToDate ? 'Up to date' : 'Update available'}
          </Tag>
        )
      )}
    </div>
    {props.downloadProgress !== null ? (
      <>
        <span className="downloadProgressText">{`Loading (${Math.floor(props.downloadProgress * 100)}%)`}</span>
        <ProgressBar className="progressBar" value={props.downloadProgress} intent={Intent.PRIMARY} />
      </>
    ) : (
      <Button className="refreshButton" onClick={props.handleDownloadData} intent={Intent.PRIMARY} icon="refresh" text="Refresh Data" />
    )}
  </div>
);

DownLoadDataContainer.propTypes = {
  dataUpToDate: PropTypes.bool.isRequired,
  handleDownloadData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
  dataFileQuery: PropTypes.shape({
    data: PropTypes.any,
    error: PropTypes.any,
    isLoading: PropTypes.bool,
  }).isRequired,
  runDetailsFileQuery: PropTypes.shape({
    data: PropTypes.any,
    error: PropTypes.any,
    isLoading: PropTypes.bool,
  }).isRequired,
  showDLButton: PropTypes.bool.isRequired,
};

DownLoadDataContainer.defaultProps = {
  downloadProgress: null,
};

export default DownLoadDataContainer;
