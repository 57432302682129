const dimensions = ['Date', 'Month', 'Year', 'Year-Month', 'Park', 'Channel'];

const trafficConv_headersRemap = {
  Park: 'Park',
  date: 'Date',
  sessionDefaultChannelGroup: 'Channel_GA',
  Channel_remapped: 'Channel',
  'customEvent:zero_dollar_transaction': 'zeroDollarTransaction',
  sessions: 'sessions',
  totalUsers: 'users',
  transactions: 'transactions',
  totalRevenue: 'revenues',
};

const trafficConv_valuesRemap = {
  Channel: {
    remaps: {
      'Organic Search': 'Organic Search',
      'Paid Search': 'Paid Search',
      Direct: 'Direct',
      Email: 'Email',
      Display: 'Display',
      Referral: 'Referral',
      Affiliates: 'Affiliates',
      Social: 'Social',
      'Paid Social': 'Social',
      'Organic Social': 'Social',
      OLV: 'OLV',
    },
    catchall: '(Other)',
  },
  // zeroDollarTransaction: {
  //   remaps: {
  //     yes: 'Yes',
  //   },
  //   catchall: 'No',
  // },
};

const emailReduced_headersRemap = {
  Park: 'Park',
  date: 'Date',
  sessionSource: 'Source',
  sessions: 'sessions',
  totalUsers: 'users',
  transactions: 'transactions',
  totalRevenue: 'revenues',
};

const emailReduced_valuesRemap = {
  Source: {
    remaps: {
      sfmc: 'SFMC',
      wunderkind: 'Wunderkind',
    },
    catchall: '(Other)',
  },
};

const filterDimensions_trafficConv = ['Park', 'Year', 'Year-Month', 'Channel'];

const dimSortings = {
  Park: ['SWF', 'APO', 'DCO', 'BGT', 'AIT', 'SWC', 'SPC', 'APC', 'SWT', 'APT', 'BGW', 'WCW', 'SPL'],
  Channel: ['Organic Search', 'Paid Search', 'Direct', 'Email', 'Social', 'Display', 'Referral', 'Affiliates', 'OLV'],
};

const timeGroupings = ['Day', 'Week', 'Month', 'Quarter', 'Year'];
const dimensionGroupings = ['Channel'];

const metricFormatMatrix = {
  'Traffic (sessions)': '0.0 a',
  'Traffic (users)': '0.0 a',
  Transactions: '0.0 a',
  'Total Revenue': '$0.0 a',
  'Conversion Rate': '0.0%',
};

const metricFormatMatrix_exact = {
  'Traffic (sessions)': '0,0',
  'Traffic (users)': '0.0',
  Transactions: '0,0',
  'Total Revenue': '$0,0',
  'Conversion Rate': '0.0%',
};

const priorYears = {
  CY: {
    color: {
      dark: '#3b93d3',
      light: '#1f76b4',
    },
    deltaYears: 0,
    deltaDays: 0,
  },
  PY: {
    color: {
      dark: '#F29D49',
      light: '#BF7326',
    },
    deltaYears: -1,
    deltaDays: -364,
  },
  'PY*': {
    color: {
      dark: '#F29D49',
      light: '#BF7326',
    },
    deltaYears: -1,
    deltaDays: -364 - 7,
  },
  '2PY': {
    color: {
      dark: '#F55656',
      light: '#C23030',
    },
    deltaYears: -2,
    deltaDays: -364 * 2,
  },
};

export {
  dimensions,
  trafficConv_headersRemap,
  trafficConv_valuesRemap,
  emailReduced_headersRemap,
  emailReduced_valuesRemap,
  filterDimensions_trafficConv,
  dimSortings,
  timeGroupings,
  dimensionGroupings,
  metricFormatMatrix,
  metricFormatMatrix_exact,
  priorYears,
};
