import React from 'react';
import PropTypes from 'prop-types';
import { DateRangeInput } from '@blueprintjs/datetime';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@blueprintjs/core';

const DateRangeFilter = (props) => {
  const { module, subModule, controlName } = props;
  const control = useSelector((store) => store.controlsReducer?.[module]?.[subModule]?.[controlName]);
  const selectedFrom = control?.selection?.from && new Date(control?.selection?.from);
  const selectedTo = control?.selection?.to && new Date(control?.selection?.to);
  const minDate = control?.values[0] ? new Date(control.values[0]) : null;
  const maxDate = control?.values[0] ? new Date(control.values.slice(-1)[0]) : null;

  const dispatch = useDispatch();
  const handleUpdateFilter = (from, to) => {
    dispatch({
      type: 'UPDATE_CONTROL_SELECTION',
      module,
      subModule,
      controlName,
      values: { from, to },
    });
  };

  const deleteSelection = () => {
    dispatch({
      type: 'UPDATE_CONTROL_SELECTION',
      module,
      subModule,
      controlName,
      values: { from: null, to: null },
    });
  };

  return (
    <div className="DateRangeFilter" style={{ width: `${props.width}px`, display: 'flex' }}>
      <DateRangeInput
        formatDate={(date) => format(date, 'MM/dd/yy')}
        onChange={([from, to]) => handleUpdateFilter(format(new Date(from), 'yyyy-MM-dd'), format(new Date(to), 'yyyy-MM-dd'))}
        value={[selectedFrom, selectedTo]}
        parseDate={(str) => new Date(str)}
        placeholder="MM/DD/YY"
        allowSingleDayRange
        // closeOnSelection={false}
        startInputProps={{ fill: true }}
        endInputProps={{ fill: true }}
        minDate={minDate}
        maxDate={maxDate}
      />
      {props.showDeleteSelection && (
        <Button minimal small onClick={deleteSelection} icon="cross" style={{ margin: '3px 1px 0 -27px', height: '25px', zIndex: 2 }} />
      )}
    </div>
  );
};

DateRangeFilter.propTypes = {
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  width: PropTypes.number,
  showDeleteSelection: PropTypes.bool,
};

DateRangeFilter.defaultProps = {
  width: 230,
  showDeleteSelection: false,
};

export default DateRangeFilter;
