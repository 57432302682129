import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import SalesAttendanceContainer from './SalesAttendance/SalesAttendanceContainer';

const EuropaContainer = (props) => (
  <div className="europa">
    <Switch>
      <Route exact path="/Europa/Sales & Attendance" component={SalesAttendanceContainer} />
    </Switch>
  </div>
);

export default EuropaContainer;
