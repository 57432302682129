import React from 'react';
import PropTypes from 'prop-types';
import { Intent } from '@blueprintjs/core';
import SimpleTable from '../../../../Components/SimpleTable/SimpleTable';
import { formatFileSize, formatDate } from '../../../../utils/util_old';
import './FileList.css';
import ButtonWithWarning from '../../../../Components/ButtonWithWarning/ButtonWithWarning';
import { fileListPropTypes } from '../../Definitions/dataPropTypes';

const FileList = ({ handleDeleteFile, ...props }) => {
  const [tableItems, setTableItems] = React.useState([]);

  const tableHeaders = [
    {
      title: 'Name',
      width: 30,
      alignHeader: 'center',
      alignItems: 'left',
    },
    {
      title: 'Size',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Type',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Upload Date',
      width: 20,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: '',
      width: 5,
      alignHeader: 'center',
      alignItems: 'center',
    },
  ];

  // When fileList changes: build tableItems array
  React.useEffect(() => {
    const newTableItems = props.fileList.map((file) => ({
      selected: file.selected,
      key: file.name,
      components: [
        <p>{file.name}</p>,
        <p>{formatFileSize(file.size)}</p>,
        <p>{file.type}</p>,
        <p>{formatDate(file.uploadDate)}</p>,
        <ButtonWithWarning
          buttonIcon="trash"
          buttonIntent={Intent.DANGER}
          warningBodyText="Are you sure you want to delete this file? This action cannot be undone"
          warningButtonText="Yes, delete"
          warningIcon="warning-sign"
          warningIntent={Intent.DANGER}
          onConfirm={() => handleDeleteFile(file)}
          loading={file.isDeleting}
          minimal
        />,
      ],
    }));
    setTableItems(newTableItems);
  }, [props.fileList, handleDeleteFile]);

  return (
    <div className="PageContainer">
      <h2>Uploaded Files</h2>
      <SimpleTable
        className="fileListTable"
        headers={tableHeaders}
        items={tableItems}
        selectAll
        handleClickSelectAll={props.handleClickSelectAll}
        selectOne
        handleClickSelect={props.handleClickSelect}
      />
    </div>
  );
};

FileList.propTypes = {
  fileList: PropTypes.arrayOf(fileListPropTypes).isRequired,
  handleClickSelect: PropTypes.func.isRequired,
  handleClickSelectAll: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
};

export default FileList;
