import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@blueprintjs/core';

const OrbitalSwitch = (props) => {
  const { module, subModule, controlName } = props;
  const control = useSelector((store) => store.controlsReducer?.[module]?.[subModule]?.[controlName]);

  const dispatch = useDispatch();

  const handleUpdateValue = () => {
    dispatch({
      type: 'UPDATE_CONTROL_SELECTION',
      module,
      subModule,
      controlName,
      values: !control?.selection,
    });
  };

  return (
    <div>
      <Switch checked={control?.selection === true} onChange={handleUpdateValue} label="Cumulative" />
    </div>
  );
};

OrbitalSwitch.propTypes = {
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
};

export default OrbitalSwitch;
