import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Slider, RangeSlider, Intent } from '@blueprintjs/core';
import { capitalize } from 'utils/util_old';
import { ETLparametersPropTypes, standardPromoETLDefinitionPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';

const BaselineParameters = (props) => {
  const baselineMethodDefinition = props.ETLdefinition.baseline.methods.find((method) => method.name === props.datasetETLparameters.baseline.method);

  return (
    <div className="ETLParamDefinition">
      <h4>Baseline</h4>
      <div className="ETLparam">
        <span className="ETLparamName">Method</span>
        <ButtonGroup>
          {props.ETLdefinition.baseline.methods.map((method) => (
            <Button
              key={method.name}
              text={capitalize(method.name)}
              onClick={() => props.handleChangeETLparameter('baseline', null, 'method', method.name)}
              intent={method.name === props.datasetETLparameters.baseline.method ? Intent.PRIMARY : Intent.NONE}
              active={method.name === props.datasetETLparameters.baseline.method}
            />
          ))}
        </ButtonGroup>
      </div>
      {baselineMethodDefinition.parameters.map((param) => {
        if (param.type === 'slider') {
          return (
            <div key={param.name} className="ETLparam">
              <span className="ETLparamName">{capitalize(param.name)}</span>
              <Slider
                min={param.range[0]}
                max={param.range[1]}
                stepSize={param.step}
                labelStepSize={param.labelStep || param.step}
                value={props.datasetETLparameters.baseline[props.datasetETLparameters.baseline.method][param.name]}
                onChange={(newValue) => props.handleChangeETLparameter('baseline', props.datasetETLparameters.baseline.method, param.name, newValue)}
              />
            </div>
          );
        }
        if (param.type === 'range-slider') {
          return (
            <div key={param.name} className="ETLparam">
              <span className="ETLparamName">{capitalize(param.name)}</span>
              <RangeSlider
                min={param.range[0]}
                max={param.range[1]}
                stepSize={param.step}
                labelStepSize={param.labelStep || param.step}
                value={props.datasetETLparameters.baseline[props.datasetETLparameters.baseline.method][param.name]}
                onChange={(newValue) => props.handleChangeETLparameter('baseline', props.datasetETLparameters.baseline.method, param.name, newValue)}
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

BaselineParameters.propTypes = {
  datasetETLparameters: ETLparametersPropTypes.isRequired,
  handleChangeETLparameter: PropTypes.func.isRequired,
  ETLdefinition: standardPromoETLDefinitionPropTypes.isRequired,
};

export default BaselineParameters;
