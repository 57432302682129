const { format, add } = require('date-fns');

const getPriorYearDateFiltersFromCYDateFilters = (dateFilters, deltaDays) => {
  const dateFiltersPriorYear = {};
  for (const [fieldName, filterValues] of Object.entries(dateFilters)) {
    dateFiltersPriorYear[fieldName] = filterValues.map((group) =>
      group.map((el) => ({ from: format(add(el.from, { days: deltaDays }), 'yyyy-MM-dd'), to: format(add(el.to, { days: deltaDays }), 'yyyy-MM-dd') })),
    );
  }
  return dateFiltersPriorYear;
};

export default getPriorYearDateFiltersFromCYDateFilters;
