import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import TrafficConversionContainer from './TrafficConversion/TrafficConversionContainer';
import WeeklyReportsContainer from './WeeklyReports/WeeklyReportsContainer';

const IoContainer = () => (
  <div className="io">
    <Switch>
      <Route exact path="/Io/Traffic & Conversion" component={TrafficConversionContainer} />
      <Route exact path="/Io/Weekly Reports" component={WeeklyReportsContainer} />
    </Switch>
  </div>
);

export default IoContainer;
