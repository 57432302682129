import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import Plotly from 'plotly.js-cartesian-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);

const LinesChart = (props) => (
  <div className="lineChart">
    <Plot
      name={props.title}
      data={[
        ...props.data.map((data) => ({
          // Reverse so that the CY curve appears on top on the chart
          x: data.x,
          y: data.y,
          name: data.name,
          mode: data.name === data.markers ? 'lines+markers' : 'lines',
          type: 'scatter',
          marker: {
            size: 6,
            color: props.isDarkMode ? data.color.dark : data.color.dark,
            line: {
              width: 1,
              color: props.isDarkMode ? '#394B59' : '#f5f8fa',
            },
          },
          line: {
            color: props.isDarkMode ? data.color.dark : data.color.dark,
            width: data.lineWidth || 1,
          },
          // hoverinfo: 'text',
          text: data.text,
          textfont: { size: 2, color: 'red' },
        })),
        ...(props.highlightWeekends
          ? [
              {
                // highlight weekends
                x: props.data[0].x,
                y: props.data[0].x.map((el) => {
                  if (new Date(el).getDay() === 0 || new Date(el).getDay() === 6) {
                    return Math.max(
                      ...props.data
                        .map((el2) => el2.y)
                        .flat()
                        .filter((el3) => !!el3),
                    );
                  }
                  return 0;
                }),
                name: 'weekends',
                type: 'bar',
                showlegend: false,
                marker: {
                  color: props.isDarkMode ? '#71808E' : '#BFCCD6',
                  opacity: 0.2,
                },
              },
            ]
          : []),
        // ].slice(0, props.data.length + (props.options.timeGrouping === 'Day' ? 1 : 0))}
      ]}
      layout={{
        width: window.innerWidth - 120,
        // width: (window.innerWidth - 120) / 2,
        height: props.height,
        title: {
          text: props.title,
          font: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
            size: 16,
          },
        },
        showlegend: true,
        legend: {
          font: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
        },
        margin: {
          t: 50,
          b: 60,
          l: 50,
          r: 50,
        },
        xaxis: {
          showgrid: false,
          tickfont: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
          rangeslider: props.rangeSlider.length === 0 ? null : {},
          range: props.rangeSlider,
        },
        yaxis: {
          showgrid: true,
          tickfont: {
            color: props.isDarkMode ? '#F5F8FA' : '#3b4e5d',
          },
          rangemode: 'tozero',
          zeroline: true,
          zerolinecolor: props.isDarkMode ? '#778793' : '#5C7080',
          automargin: true,
          gridcolor: props.isDarkMode ? '#4A5D6C' : '#ced9e0',
        },
        paper_bgcolor: props.isDarkMode ? '#394B59' : '#f5f8fa',
        plot_bgcolor: props.isDarkMode ? '#394B59' : '#f5f8fa',
        bargap: 0,
        // dragmode: 'select',
      }}
      config={
        {
          // displayModeBar: false,
          // responsive: true,
        }
      }
      onRelayout={(event) => {
        // console.log(event);
      }}
      onSelected={(event) => {}}
    />
    <Button className="copyToClipboard" icon="clipboard" minimal onClick={props.copyToClipboard} />
  </div>
);

LinesChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      x: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      y: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
      text: PropTypes.arrayOf(PropTypes.string),
      markers: PropTypes.bool,
      lineWidth: PropTypes.number,
      color: PropTypes.shape({
        dark: PropTypes.string,
        light: PropTypes.string,
      }),
    }),
  ).isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  highlightWeekends: PropTypes.bool.isRequired,
  rangeSlider: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default LinesChart;
