/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import './MyMultiSelect.css';

const MyMultiSelect = (props) => {
  const [ctrlIsPressed, setCtrlIsPressed] = React.useState(false);
  const onItemSelect = (newItem) => {
    if (props.selectedItems.includes(newItem)) {
      if (!props.ctrlSelectMode || ctrlIsPressed) {
        // if selected, unselect it
        props.updateSelectedItems(props.selectedItems.filter((item) => item !== newItem));
      } else {
        // Select just that item
        props.updateSelectedItems([newItem]);
      }
    } else if (!props.ctrlSelectMode || ctrlIsPressed) {
      // if not selected, add it
      props.updateSelectedItems([...props.selectedItems, newItem]);
    } else {
      // Select just that one
      props.updateSelectedItems([newItem]);
    }
  };

  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 17 || e.keyCode === 91) {
        // 91 is Cmd key on Mac (Windows key on Windows)
        setCtrlIsPressed(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.keyCode === 17 || e.keyCode === 91) {
        // 91 is Cmd key on Mac (Windows key on Windows)
        setCtrlIsPressed(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    // Don't forget to clean up
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onItemRemove = (removedItem) => {
    props.updateSelectedItems(props.selectedItems.filter((item) => item !== removedItem));
  };

  const removeAllItems = () => {
    props.updateSelectedItems([]);
  };

  const clearButton = props.items.length > 0 ? <Button icon="cross" minimal onClick={removeAllItems} /> : undefined;

  const filterItems = (query, item, index, exactMatch) => {
    const normalizedItem = item.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedItem === normalizedQuery;
    }
    return normalizedItem.includes(normalizedQuery);
  };
  // eslint-disable-next-line no-unused-vars
  const itemRenderer = (item, { index, handleClick, query }) => {
    const itemIsActive = props.selectedItems.includes(item);
    return <MenuItem style={{ margin: '4px 0' }} key={item} text={item} onClick={handleClick} active={itemIsActive} icon={itemIsActive ? 'tick' : 'blank'} />;
  };

  const tagRenderer = (item) => item;

  return (
    <MultiSelect
      // inputValueRenderer={item => item}
      items={props.items}
      selectedItems={props.selectedItems}
      itemPredicate={filterItems}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      popoverProps={{ popoverClassName: 'MyMultiSelectPopover' }}
      tagRenderer={tagRenderer}
      noResults={<MenuItem disabled text="No results" />}
      tagInputProps={{ onRemove: onItemRemove, rightElement: clearButton }}
      placeholder={props.placeholder}
      fill="true"
    />
  );
};

MyMultiSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  updateSelectedItems: PropTypes.func.isRequired,
  ctrlSelectMode: PropTypes.bool,
  placeholder: PropTypes.string,
};

MyMultiSelect.defaultProps = {
  items: [],
  selectedItems: [],
  ctrlSelectMode: true,
  placeholder: 'Search...',
};

export default MyMultiSelect;
