import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

// export default class MySelect extends Component {
const MySelect = (props) => {
  const itemRenderer = (item, { handleClick, modifiers, query }) => (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      // label={item}
      key={item}
      onClick={handleClick}
      text={item}
    />
  );

  const itemPredicate = (query, item, _index, exactMatch) => {
    const normalizedDatasetName = item.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedDatasetName === normalizedQuery;
    }
    return normalizedDatasetName.indexOf(normalizedQuery) >= 0;
  };

  const createNewItemFromQuery = (itemName) => {
    props.handleItemCreate(itemName);
    return itemName;
  };

  const createNewItemRenderer = (query, active, handleClick) => (
    <MenuItem icon="add" text={`Create "${query}"`} active={active} onClick={handleClick} shouldDismissPopover={false} />
  );

  return (
    <Select
      items={props.items}
      activeItem={props.activeItem}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      onItemSelect={props.handleItemSelect}
      noResults={<MenuItem disabled text="No results" />}
      createNewItemFromQuery={props.allowCreate ? createNewItemFromQuery : undefined}
      createNewItemRenderer={props.allowCreate ? createNewItemRenderer : undefined}
      popoverProps={{ minimal: true }}
      filterable={props.filterable}
      fill={props.fill}
    >
      <Button
        text={props.activeItem}
        rightIcon="caret-down"
        // style={{ width: `${props.width}px`, display: 'flex', justifyContent: 'space-between' }}
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      />
    </Select>
  );
};

MySelect.propTypes = {
  activeItem: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleItemSelect: PropTypes.func.isRequired,
  allowCreate: PropTypes.bool,
  handleItemCreate: PropTypes.func,
  filterable: PropTypes.bool,
  fill: PropTypes.bool,
  width: PropTypes.number,
};

MySelect.defaultProps = {
  activeItem: null,
  allowCreate: false,
  handleItemCreate: undefined,
  filterable: false,
  fill: false,
  width: 200,
};

export default MySelect;
