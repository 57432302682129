import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Intent, Button, Icon, Switch, Spinner } from '@blueprintjs/core';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import SimpleTable from 'Components/SimpleTable/SimpleTable';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import { formatFileSize, formatDate, capitalize } from 'utils/util_old';
import { datasetPropTypes } from '../Definitions/dataPropTypes';
import './Datasets.css';

const Datasets = ({ datasets, handleDeleteDataset, ...props }) => {
  const [tableItems, setTableItems] = React.useState([]);

  const tableHeaders = [
    {
      title: 'Name',
      width: 27,
      alignHeader: 'center',
      alignItems: 'left',
    },
    {
      title: 'Created',
      width: 8,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Last Run',
      width: 8,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Size',
      width: 8,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Data Source',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Status',
      width: 7,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Action',
      width: 15,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Configure',
      width: 7,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: '', // delete button
      width: 5,
      alignHeader: 'center',
      alignItems: 'center',
    },
  ];

  const actionButton = (datasetStatus) => {
    switch (datasetStatus) {
      case 'Ready':
        return <Button rightIcon="log-out" text="Dashboards" intent={Intent.PRIMARY} />;
      case 'Running':
        return <Button rightIcon="delete" text="Cancel Run" intent={Intent.DANGER} />;
      case 'Error':
        return <Button rightIcon="undo" text="Revert" intent={Intent.WARNING} />;
      default:
        return null;
    }
  };

  const getStatusIcon = (datasetStatus) => {
    switch (datasetStatus) {
      case 'Ready':
        return <Icon icon="tick-circle" intent={Intent.SUCCESS} />;
      case 'Running':
        return <Spinner size={Spinner.SIZE_SMALL} intent={Intent.PRIMARY} />;
      case 'Failed':
        return <Icon icon="error" intent={Intent.DANGER} />;
      default:
        return '';
    }
  };

  // When datasets changes: build tableItems array
  React.useEffect(() => {
    const newTableItems = datasets.map((dataset) => ({
      selected: dataset.selected,
      key: dataset.name,
      components: [
        <p>{dataset.name}</p>,
        <p>{formatDate(dataset.creationDate)}</p>,
        <p>{formatDate(dataset.lastRunDate)}</p>,
        <p>{formatFileSize(dataset.size)}</p>,
        <p>{capitalize(dataset.dataSource)}</p>,
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {getStatusIcon(dataset.status)}
          <span style={{ marginLeft: '10px' }}>{capitalize(dataset.status)}</span>
        </div>,
        actionButton(dataset.status),
        <Link to={`/Data/Dataset/${encodeURI(dataset.name)}`}>
          <Button icon="settings" text="" />
        </Link>,
        <ButtonWithWarning
          buttonIcon="trash"
          buttonIntent={Intent.DANGER}
          warningBodyText="Are you sure you want to delete this dataset? This action cannot be undone"
          warningButtonText="Yes, delete"
          warningIcon="warning-sign"
          warningIntent={Intent.DANGER}
          onConfirm={() => handleDeleteDataset(dataset.name)}
          loading={dataset.isDeleting}
          minimal
        />,
      ],
    }));
    setTableItems(newTableItems);
  }, [datasets, handleDeleteDataset]);

  return (
    <div className="Datasets">
      <MyBreadCrumbs
        items={[
          { href: '/', text: 'Home' },
          { href: '/Data', text: 'Data' },
          { href: '/Data/Datasets', text: 'Datasets' },
        ]}
      />
      <div className="PageContainer">
        <div className="datasetsHeader">
          <h2>Datasets</h2>
          <Switch checked={props.datasetsPulseIsOn} label="Pulse" onChange={props.handleClickDatasetPulse} />
        </div>
        <SimpleTable
          className="datasetListTable"
          headers={tableHeaders}
          items={tableItems}
          selectAll
          handleClickSelectAll={props.handleClickSelectAll}
          selectOne
          handleClickSelect={props.handleClickSelect}
          isLoading={props.isLoading}
        />
        <div className="datasetsButtons">
          <Button large icon="add" intent={Intent.PRIMARY} text="Create New Dataset" onClick={props.handleAddDataset} />
          <Button large icon="duplicate" text="Duplicate Dataset" onClick={props.handleDuplicateDataset} />
        </div>
      </div>
    </div>
  );
};

Datasets.propTypes = {
  datasets: PropTypes.arrayOf(datasetPropTypes).isRequired,
  handleAddDataset: PropTypes.func.isRequired,
  handleDuplicateDataset: PropTypes.func.isRequired,
  handleDeleteDataset: PropTypes.func.isRequired,
  handleClickSelectAll: PropTypes.func.isRequired,
  handleClickSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  datasetsPulseIsOn: PropTypes.bool.isRequired,
  handleClickDatasetPulse: PropTypes.func.isRequired,
};

export default Datasets;
