import React from 'react';
import PropTypes from 'prop-types';
import './FilesInputConfiguration.css';
import { HTMLSelect, Button, Dialog } from '@blueprintjs/core';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import { capitalize, formatFileSize, formatDate } from 'utils/util_old';
import SimpleTable from 'Components/SimpleTable/SimpleTable';
import ETLdefinitions from 'AppModules/Data/Definitions/ETLdefinitions';
import FieldsConfigurationContainer from './FieldsConfiguration/FieldsConfigurationContainer';

const FilesInputConfiguration = ({ handleChangeFileRole, ...props }) => {
  const [tableItems, setTableItems] = React.useState([]);
  const [fieldsDialog, setFieldsDialog] = React.useState({ isOpen: false });

  const handleClose = () => {
    setFieldsDialog({ ...fieldsDialog, isOpen: false });
  };

  const tableHeaders = [
    {
      title: 'Name',
      width: 25,
      alignHeader: 'center',
      alignItems: 'left',
    },
    {
      title: 'Size',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Type',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Role',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Upload Date',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Fields',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Mapping',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Status',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Edit',
      width: 5,
      alignHeader: 'center',
      alignItems: 'center',
    },
  ];

  // When fileList changes: build tableItems array
  React.useEffect(() => {
    const newTableItems = props.dataset.files.map((file) => ({
      selected: file.selected,
      key: file.name,
      components: [
        <p>{file.name}</p>,
        <p>{formatFileSize(file.size)}</p>,
        <p>{file.type}</p>,
        <HTMLSelect
          options={ETLdefinitions.find((schema) => schema.name === props.dataset.ETLschema).fileRoles.map((role) => role.name)}
          value={file.role}
          onChange={(e) => handleChangeFileRole(file.name, e.currentTarget.value)}
        />,
        <p>{formatDate(file.uploadDate)}</p>,
        <p>
          {file.fields.filter((field) => field.selected).length}/{file.fields.length} selected
        </p>,
        <p>
          {file.fields.filter((field) => field.selected).filter((field) => field.name !== field.mappedTo).length === 0
            ? 'none'
            : `${file.fields.filter((field) => field.selected).filter((field) => field.name !== field.mappedTo).length}
            /
            ${file.fields.filter((field) => field.selected).length} mapped`}
        </p>,
        <p>OK</p>,
        <Button icon="settings" onClick={() => setFieldsDialog({ isOpen: true, fileName: file.name })} />,
      ],
    }));
    setTableItems(newTableItems);
  }, [props.dataset, handleChangeFileRole]);

  return (
    <div className="FilesInputConfiguration">
      <h3>{`${props.dataset.dataSource
        .split('-')
        .map((str) => capitalize(str))
        .join(' ')} — Configuration`}</h3>
      <SimpleTable
        className="inputFilesTable"
        headers={tableHeaders}
        items={tableItems}
        selectAll
        handleClickSelectAll={props.handleClickSelectAll}
        selectOne
        handleClickSelect={props.handleClickSelect}
      />
      {fieldsDialog.isOpen ? ( // Needed because otherwise FieldsConfigurationContainer has empty props at mounting (before dataset props are downloaded)
        <Dialog
          isOpen={fieldsDialog.isOpen}
          onClose={() => setFieldsDialog({ ...fieldsDialog, isOpen: false })}
          canEscapeKeyClose={false}
          icon="settings"
          title={`${fieldsDialog.fileName} - Fields configuration`}
          style={{ width: '70%' }}
        >
          <FieldsConfigurationContainer
            dataset={props.dataset}
            file={props.dataset.files.find((el) => el.name === fieldsDialog.fileName)}
            handleClose={handleClose}
          />
        </Dialog>
      ) : null}
    </div>
  );
};

FilesInputConfiguration.propTypes = {
  dataset: datasetPropTypes.isRequired,
  handleClickSelectAll: PropTypes.func.isRequired,
  handleClickSelect: PropTypes.func.isRequired,
  handleChangeFileRole: PropTypes.func.isRequired,
};

export default FilesInputConfiguration;
