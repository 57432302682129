/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Intent, Toaster } from '@blueprintjs/core';
import TritonAPI from 'AppModules/Triton/API/TritonAPI';
import DownloadData from './DownloadData';
import './DownloadData.css';

const dataToaster = Toaster.create();

const DownLoadDataContainer = (props) => {
  const token = useSelector((store) => store.authReducer.token || '');

  const [downloadProgress, setDownloadProgress] = React.useState();

  const [dataUpToDate, setDataUpToDate] = React.useState(false);

  // Get pre-signed S3 link for full db file
  const dataFileQuery = useQuery([`${props.mainFile}_mainFileLink`], () => TritonAPI.getS3PresignedLink(token, props.mainFile));
  const runDetailsFileQuery = useQuery([`${props.runDetailsFile}runDetailsFileLink`], () => TritonAPI.getS3PresignedLink(token, props.runDetailsFile));

  const handleDownloadData = async () => {
    // eslint-disable-next-line no-unused-vars
    let isSubscribed = true; // needed because the async function could resolve after the component has unmounted

    setDownloadProgress(0); // Set progress to show that download has started (instead of waiting for the first progress event)
    try {
      const onDownloadProgress = (progressEvent) => setDownloadProgress(progressEvent.loaded / progressEvent.total);
      const data = await TritonAPI.downloadData(token, props.mainFile, onDownloadProgress);
      props.onDataLoaded(data);
    } catch (err) {
      dataToaster.show({ message: `Error downloading the data file: ${err.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
    } finally {
      setDownloadProgress(null);
    }
    return () => {
      isSubscribed = false;
    };
  };

  return (
    <DownloadData
      downloadProgress={downloadProgress}
      dataUpToDate={dataUpToDate}
      handleDownloadData={handleDownloadData}
      dataFileQuery={dataFileQuery}
      runDetailsFileQuery={runDetailsFileQuery}
      showDLButton={process.env.REACT_APP_STAGE !== 'production' && props.showDLButton}
    />
  );
};

DownLoadDataContainer.propTypes = {
  mainFile: PropTypes.string.isRequired,
  runDetailsFile: PropTypes.string.isRequired,
  showDLButton: PropTypes.bool,
  onDataLoaded: PropTypes.func.isRequired,
};

DownLoadDataContainer.defaultProps = {
  showDLButton: false,
};

export default DownLoadDataContainer;
