import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import ioReducer from 'AppModules/Io/ioReducer';
import authReducer from '../Auth/authReducer';
import controlsReducer from '../reducers/controlsReducer';
import userReducer from '../reducers/userReducer';
import dataGeneratorReducer from '../AppModules/Data/Reducers/dataGeneratorReducer';
import uploadReducer from '../AppModules/Data/Reducers/uploadReducer';
import datasetsReducer from '../AppModules/Data/Reducers/datasetsReducer';
import ganymedeReducer from '../AppModules/Ganymede/ganymedeReducer';
import titanReducer from '../AppModules/Titan/Reducers/titanReducer';
import europaReducer from '../AppModules/Europa/EuropaReducer';

const reducers = combineReducers({
  authReducer,
  controlsReducer,
  userReducer,
  dataGeneratorReducer,
  uploadReducer,
  datasetsReducer,
  ganymedeReducer,
  titanReducer,
  europaReducer,
  ioReducer,
});

export default createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));
