/* eslint-disable import/prefer-default-export */
import { differenceInDays, format } from 'date-fns';

const getStateGroup = (SOR, State) => {
  if (SOR === 'International') return 'International';
  if (State === 'FL') return 'Domestic FL';
  return 'Domestic Non-FL';
};

const datasetParams = {
  mainFile: 'Outputs/Latest/DCO Bookings.csv',
  runDetailsFile: 'Outputs/Latest/DCO Bookings - run details.csv',
  mainDateField: 'BookDate',
  headersRemaps: {
    BookDate: 'BookDate',
    EventStartDate: 'EventDate',
    ChannelSKP: 'Channel',
    SOR: 'SOR',
    State: 'State',
    Item_Type: 'ItemType',
    SalesUnits: 'Units',
    Revenue: 'Revenues',
  },
  transformRow: (row) => ({
    ...row,
    StateGroup: getStateGroup(row.SOR, row.State),
    BookDate: format(new Date(row.BookDate), 'yyyy-MM-dd'),
    BookYear: format(new Date(row.BookDate), 'yyyy'),
    BookYearMonth: format(new Date(row.BookDate), 'yyyy-MM'),
    EventDate: format(new Date(row.EventDate), 'yyyy-MM-dd'),
    EventYear: format(new Date(row.EventDate), 'yyyy'),
    EventYearMonth: format(new Date(row.EventDate), 'yyyy-MM'),
    leadTime: differenceInDays(new Date(row.BookDate), new Date(row.EventDate)),
  }),
  filterDimensions: {
    BookDate: {
      order: 'asc',
    },
    BookYear: {
      order: 'desc',
    },
    BookYearMonth: {
      order: 'desc',
    },
    EventDate: {
      order: 'asc',
    },
    EventYear: {
      order: 'desc',
    },
    EventYearMonth: {
      order: 'desc',
    },
    Channel: {
      order: 'asc',
    },
    SOR: {
      order: 'asc',
      customOrder: ['Local', 'Same Day', 'Drive & Overnight', 'Domestic', 'International'],
    },
    StateGroup: {
      order: 'asc',
    },
    State: {
      order: 'asc',
    },
    ItemType: {
      order: 'asc',
    },
  },
};

export default datasetParams;
