import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import NavBarContainer from '../NavBar/NavBarContainer';
import SideBar from '../SideBar/SideBar';
import { sideBarItems } from '../../utils/appDefinitions';
import Home from '../Home/Home';
// imoprt components as defined in appDefinitions
import DataPage from '../../AppModules/Data/DataPage';
import GanymedeContainer from '../../AppModules/Ganymede/GanymedeContainer';
// import TitanContainer from '../../AppModules/Titan/TitanContainer';
import EuropaContainer from '../../AppModules/Europa/EuropaContainer';
import IoContainer from '../../AppModules/Io/IoContainer';
import CallistoContainer from '../../AppModules/Callisto/CallistoContainer';
import AdminContainer from '../../AppModules/Admin/AdminContainer';
import './MainContainer.css';
import userActions from '../../reducers/userActions';

const MainContainer = (props) => {
  const handleToggleDarkMode = () => {
    props.toggleDarkMode();
  };

  const style = {
    margin: '50px 0 0 67px',
    padding: '5px',
  };

  return (
    <div style={style}>
      <NavBarContainer isDarkMode={props.isDarkMode} handleToggleDarkMode={handleToggleDarkMode} />
      <SideBar items={sideBarItems} modulesAccess={props.user['https://orbital.hdhconsulting.be//app_metadata']?.modulesAccess} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/Data" component={DataPage} />
        {/* <Route path="/Titan" component={TitanContainer} /> */}
        <Route path="/Ganymede" component={GanymedeContainer} />
        <Route path="/Europa" component={EuropaContainer} />
        <Route path="/Callisto" component={CallistoContainer} />
        <Route path="/Io" component={IoContainer} />
        {/* <Route path="/Admin" component={AdminContainer} /> */}
      </Switch>
    </div>
  );
};

MainContainer.propTypes = {
  isDarkMode: PropTypes.bool,
  toggleDarkMode: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    email_verified: PropTypes.bool.isRequired,
    'https://orbital.hdhconsulting.be//app_metadata': PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      modulesAccess: PropTypes.object,
    }).isRequired,
    name: PropTypes.string,
    nickname: PropTypes.string,
    picture: PropTypes.string,
    sub: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

MainContainer.defaultProps = {
  isDarkMode: false,
};

const mapStateToProps = (store) => ({
  isDarkMode: store.userReducer.userSettings.isDarkMode,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDarkMode: () => dispatch(userActions.toggleDarkMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
