import React from 'react';
import PropTypes from 'prop-types';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
import MySelect from 'Components/MySelect/MySelect';
import { useDispatch, useSelector } from 'react-redux';

const Filter = (props) => {
  const { module, subModule, controlName } = props;
  const control = useSelector((store) => store.controlsReducer?.[module]?.[subModule]?.[controlName]);

  const dispatch = useDispatch();

  const handleUpdateFilter = (newItems) => {
    dispatch({
      type: 'UPDATE_CONTROL_SELECTION',
      module,
      subModule,
      controlName,
      values: newItems,
    });
  };

  if (!props.values && !control?.values) {
    return <span>Loading</span>;
  }
  return (
    <div style={{ width: props.width }}>
      {props.multiple ? (
        <MyMultiSelect
          items={props.values || control?.values || []}
          selectedItems={control?.selection || []}
          updateSelectedItems={(newItems) => handleUpdateFilter(newItems)}
          ctrlSelectMode
          placeholder={props.placeholder}
          fill
        />
      ) : (
        <MySelect
          items={props.values || control?.values || []}
          activeItem={control?.selection ? control?.selection[0] : ''}
          handleItemSelect={(newItems) => handleUpdateFilter([newItems])}
          filterable={false}
          fill
        />
      )}
    </div>
  );
};

Filter.propTypes = {
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  width: PropTypes.number,
};
Filter.defaultProps = {
  multiple: false,
  values: null,
  placeholder: '...Search',
  width: 250,
};

export default Filter;
