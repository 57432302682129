import React from 'react';
import PropTypes from 'prop-types';
import { HTMLTable } from '@blueprintjs/core';
import numeral from 'numeral';
import './WeeklyReportTable.css';

const WeeklyReportTable = (props) => {
  // console.log(props.weeklyEmailReportData);
  if (!props.weeklyReportData.CY) {
    return <p>Loading...</p>;
  }

  const buildRow = (channel, metric, subtotal = false) => {
    const getCell = (period, metricType = 'CY') => {
      // Filter for period only
      const period_Data_CY = props.weeklyReportData.CY.find((el) => el.period === period) || [];
      const period_Data_PY = props.weeklyReportData.PY.find((el) => el.period === period) || [];

      // Filter for channel only
      const period_channel_Data_CY = subtotal
        ? period_Data_CY?.data.reduce((acc, curr) => ({ [metric]: acc[metric] + curr[metric] }), { [metric]: 0 })
        : period_Data_CY?.data.find((el) => el.Channel === channel) || [];

      const period_channel_Data_PY = subtotal
        ? period_Data_PY?.data.reduce((acc, curr) => ({ [metric]: acc[metric] + curr[metric] }), { [metric]: 0 })
        : period_Data_PY?.data.find((el) => el.Channel === channel) || [];

      if (metricType === 'vs. PY units') {
        const value = period_channel_Data_CY[metric] - period_channel_Data_PY[metric];
        return <td className={value >= 0 ? '' : 'negative'}>{numeral(value).format('+0,0')}</td>;
      }
      if (metricType === 'vs. PY %') {
        const value = period_channel_Data_CY[metric] / period_channel_Data_PY[metric] - 1;
        return <td className={value >= 0 ? '' : 'negative'}>{numeral(value).format('+0.0%')}</td>;
      }
      const value = period_channel_Data_CY[metric];
      return <td>{numeral(value).format('0,0')}</td>;
    };

    return (
      <tr className={subtotal ? 'totalRow' : ''}>
        {channel === 'Organic Search' && (
          <td rowSpan="11" className="rowHeader">
            {metric[0].toUpperCase() + metric.slice(1)}
          </td>
        )}
        <td className="rowHeader">{channel}</td>

        {/* {getCell('W-5', 'CY')} */}
        {/* {getCell('W-4', 'CY')} */}
        {/* {getCell('W-3', 'CY')} */}
        {getCell('M-1', 'CY')}
        {getCell('MTD', 'CY')}
        {getCell('W-2', 'CY')}
        {getCell('LW', 'CY')}
        {/* {getCell('TOTAL', 'CY')} */}
        {/* {getCell('W-5', 'vs. PY units')} */}
        {/* {getCell('W-4', 'vs. PY units')} */}
        {/* {getCell('W-3', 'vs. PY units')} */}
        {getCell('M-1', 'vs. PY units')}
        {getCell('MTD', 'vs. PY units')}
        {getCell('W-2', 'vs. PY units')}
        {getCell('LW', 'vs. PY units')}
        {/* {getCell('TOTAL', 'vs. PY units')} */}
        {/* {getCell('W-5', 'vs. PY %')} */}
        {/* {getCell('W-4', 'vs. PY %')} */}
        {/* {getCell('W-3', 'vs. PY %')} */}
        {getCell('M-1', 'vs. PY %')}
        {getCell('MTD', 'vs. PY %')}
        {getCell('W-2', 'vs. PY %')}
        {getCell('LW', 'vs. PY %')}
        {/* {getCell('TOTAL', 'vs. PY %')} */}
      </tr>
    );
  };

  return (
    <div className="weeklyReportTable" width="100%">
      <HTMLTable bordered interactive width="100%" style={{ fontSize: 12 }}>
        <thead>
          <tr>
            <th> </th>
            <th> </th>
            <th colSpan="4">2024</th>
            <th colSpan="4">vs. 2023 units</th>
            <th colSpan="4">vs. 2023 %</th>
          </tr>
          <tr>
            <th> </th>
            <th>Channel</th>
            {/* <th>W-5</th>
            <th>W-4</th>
            <th>W-3</th> */}
            <th>M-1</th>
            <th>MTD</th>
            <th>W-2</th>
            <th>LW</th>
            {/* <th>TOTAL</th> */}
            {/* <th>W-5</th>
            <th>W-4</th>
            <th>W-3</th> */}
            <th>M-1</th>
            <th>MTD</th>
            <th>W-2</th>
            <th>LW</th>
            {/* <th>TOTAL</th> */}
            {/* <th>W-5</th>
            <th>W-4</th>
            <th>W-3</th> */}
            <th>M-1</th>
            <th>MTD</th>
            <th>W-2</th>
            <th>LW</th>
            {/* <th>TOTAL</th> */}
          </tr>
        </thead>
        <tbody>
          {buildRow('Organic Search', 'sessions')}
          {buildRow('Paid Search', 'sessions')}
          {buildRow('Direct', 'sessions')}
          {buildRow('Email', 'sessions')}
          {buildRow('Social', 'sessions')}
          {buildRow('Referral', 'sessions')}
          {buildRow('Display', 'sessions')}
          {buildRow('Affiliates', 'sessions')}
          {buildRow('OLV', 'sessions')}
          {buildRow('(Other)', 'sessions')}
          {buildRow('Total', 'sessions', true)}
          {buildRow('Organic Search', 'transactions')}
          {buildRow('Paid Search', 'transactions')}
          {buildRow('Direct', 'transactions')}
          {buildRow('Email', 'transactions')}
          {buildRow('Social', 'transactions')}
          {buildRow('Referral', 'transactions')}
          {buildRow('Display', 'transactions')}
          {buildRow('Affiliates', 'transactions')}
          {buildRow('OLV', 'transactions')}
          {buildRow('(Other)', 'transactions')}
          {buildRow('Total', 'transactions', true)}
        </tbody>
      </HTMLTable>
    </div>
  );
};

WeeklyReportTable.propTypes = {
  weeklyReportData: PropTypes.shape({
    CY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
    PY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
  }).isRequired,
};

export default WeeklyReportTable;
