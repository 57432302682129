import React from 'react';
import PropTypes from 'prop-types';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
import MySelect from 'Components/MySelect/MySelect';
import { DateRangeInput } from '@blueprintjs/datetime';
import { format, sub } from 'date-fns';

const Filters = (props) => (
  <div className="filters">
    {props.includeDates && (
      <>
        <h4>Date Ranges</h4>
        <section className="primaryDateFilter">
          <DateRangeInput
            formatDate={(date) => format(date, 'MM/dd/yy')}
            onChange={([from, to]) => props.handleUpdateFilter('date', [format(from, 'yyyy-MM-dd'), format(to, 'yyyy-MM-dd')])}
            value={[
              new Date(props.filtersSelection?.date[0]) || sub(new Date(), { days: 8 }),
              new Date(props.filtersSelection?.date[1]) || sub(new Date(), { days: 1 }),
            ]}
            parseDate={(str) => new Date(str)}
            placeholder="MM/DD/YY"
            allowSingleDayRange
            // closeOnSelection={false}
            startInputProps={{ fill: true }}
            endInputProps={{ fill: true }}
          />
        </section>
      </>
    )}
    {Object.keys(props.filterValues).map((filterName) =>
      filterName === 'Park' ? (
        <section key={filterName} className={filterName}>
          <h4>{filterName}</h4>
          <MySelect
            width={80}
            items={props.filterValues[filterName]}
            activeItem={props.filtersSelection[filterName] ? props.filtersSelection[filterName][0] : props.filterValues[filterName][0]}
            handleItemSelect={(newItems) => props.handleUpdateFilter(filterName, [newItems])}
            filterable={false}
            fill
          />
        </section>
      ) : (
        <section key={filterName} className={filterName}>
          <h4>{filterName}</h4>
          <MyMultiSelect
            items={props.filterValues[filterName]}
            selectedItems={props.filtersSelection[filterName]}
            updateSelectedItems={(newItems) => props.handleUpdateFilter(filterName, newItems)}
            ctrlSelectMode
          />
        </section>
      ),
    )}
  </div>
);

Filters.propTypes = {
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  filtersSelection: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  includeDates: PropTypes.bool.isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  filtersSelection: {},
};

export default Filters;
