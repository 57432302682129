/* eslint-disable no-case-declarations */
/* eslint-disable no-continue */
import React from 'react';
import localforage from 'localforage';
import engineWorker from 'Data/worker';
import { Intent, Toaster } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import priorYearsDefinitions from 'utils/priorYears';
import { priorYears } from 'AppModules/Io/utils/constants';
import { add, format, sub } from 'date-fns';
import DCOBookings from './DCOBookings';
import datasetParams from './utils/datasetParams';
import transformData from './utils/transformData';
import getFiltersFromControls from './utils/getFiltersFromControls';
import getPriorYearDateFiltersFromCYDateFilters from './utils/getPriorYearDateFiltersFromCYDateFilters';

const toaster = Toaster.create();
const TABLE_NAME = 'Callisto_DCOBookings';
let dataHasLoaded = false;

const DCOBookingsContainer = () => {
  const dispatch = useDispatch();
  const controls = useSelector((store) => store.controlsReducer?.Callisto?.DCOBookings);

  // STATE
  const [bookingsData, setBookingsData] = React.useState({});
  const [dataInfo, setDataInfo] = React.useState();

  // DERIVED STATE
  const xDimension = controls?.xDimension?.selection[0] === 'By Book Date' ? 'BookDate' : 'leadTime';
  const rangeSlider = xDimension === 'leadTime' ? [-180, 0] : [format(sub(new Date(), { days: 180 }), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')];

  // UPDATE DATA WHEN CONTROLS CHANGE
  React.useEffect(() => {
    if (dataInfo) {
      // Don't ask for data if there is no data
      const options = {
        cumulative: controls?.toggleCumulative?.selection,
        timeGrouping: controls?.timeGrouping?.selection,
      };

      const { filters, dateFilters } = getFiltersFromControls(controls, datasetParams.filterDimensions);
      // console.log({ filters, dateFilters });

      const CY_request = {
        name: 'CY',
        metric: 'Units',
        dimension: xDimension,
        options,
        filters,
        dateFilters,
      };

      let PY_request;
      const priorYear = controls?.priorYears?.selection[0];

      if (priorYear) {
        const { deltaDays } = priorYearsDefinitions[priorYear];
        const dateFiltersPriorYear = getPriorYearDateFiltersFromCYDateFilters(dateFilters, deltaDays);
        // console.log({ dateFiltersPriorYear });
        PY_request = {
          ...CY_request,
          name: priorYear,
          dateFilters: dateFiltersPriorYear,
        };
      }
      const requests = [CY_request];
      if (PY_request) requests.push(PY_request);
      // console.log({ requests });

      engineWorker.postMessage({
        type: 'getData',
        tableName: TABLE_NAME,
        requests,
      });
    }
  }, [controls, dataInfo]);

  // LOAD DATA ON MOUNT
  React.useEffect(() => {
    engineWorker.onmessage = ({ data }) => {
      const { type } = data;
      switch (type) {
        case 'loadData':
          const { nbRows, fields, lastDate, filterValues } = data.loadDataOutput[TABLE_NAME];
          dispatch({
            type: 'UPDATE_ALL_CONTROL_VALUES',
            module: 'Callisto',
            subModule: 'DCOBookings',
            filterValues,
          });
          setDataInfo({ nbRows, fields, lastDate });
          break;
        case 'getData':
          const newData = {};
          for (const dataOutput of data.getDataOutputs) {
            // const newData = data.getDataOutput.map((dataOutput) => {

            const { name, dimensions, metrics, request } = dataOutput;
            let x = [];
            let y = [];

            if (request.dimension === 'leadTime') {
              // truncate to leadTime of max 2 years
              for (let i = 0; i < dimensions.length; i += 1) {
                if (dimensions[i] > -730) {
                  x.push(dimensions[i]);
                  y.push(metrics[i]);
                }
              }
            } else if (request.dimension === 'BookDate' && name !== 'CY') {
              // re-align the dates for prior years
              const { deltaDays } = priorYearsDefinitions[name];
              x = dimensions.map((el) => format(add(new Date(el), { days: deltaDays * -1 }), 'yyyy-MM-dd'));
              y = metrics;
            } else {
              x = dimensions;
              y = metrics;
            }
            // console.log({ name, x, y });
            newData[name] = {
              x,
              y,
              markers: name === 'CY', // Adding chart parameters
              lineWidth: name === 'CY' ? 2 : 1,
              color: priorYears[name].color,
            };
          }
          setBookingsData(newData);
          break;
        default:
          break;
      }
    };

    // Load Data
    const asyncLoadLocalData = async () => {
      const localData = await localforage.getItem(TABLE_NAME);
      if (localData) {
        engineWorker.postMessage({
          type: 'loadData',
          tableName: TABLE_NAME,
          data: localData,
          params: { mainDateField: datasetParams.mainDateField, filterDimensions: datasetParams.filterDimensions },
        });
        dataHasLoaded = true;
      } else {
        toaster.show({ message: 'No data on this device.', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    };
    // if (!dataHasLoaded)
    asyncLoadLocalData();
  }, []);

  const onDataLoaded = (rawData) => {
    const data = transformData(rawData, datasetParams.headersRemaps, datasetParams.transformRow);

    localforage.setItem(TABLE_NAME, data);
    engineWorker.postMessage({
      type: 'loadData',
      tableName: TABLE_NAME,
      data,
      params: { mainDateField: datasetParams.mainDateField, filterDimensions: datasetParams.filterDimensions },
    });
  };

  return (
    <DCOBookings
      mainFile={datasetParams.mainFile}
      runDetailsFile={datasetParams.runDetailsFile}
      onDataLoaded={onDataLoaded}
      bookingsData={Object.entries(bookingsData)
        .map(([seriesName, data]) => ({ name: seriesName, ...data }))
        .reverse()}
      rangeSlider={rangeSlider}
    />
  );
};

export default DCOBookingsContainer;
