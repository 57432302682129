/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LinesChart from './LinesChart';
import './LinesChart.css';

const LinesChartContainer = (props) => {
  const { isDarkMode } = useSelector((store) => store.userReducer.userSettings);
  // console.log(props.data);

  const copyToClipboard = () => {
    // TO-DO: need to find a way to merge the different data series, where for each value of x there may or may not be a y values
    // for transposing an array, see: https://stackoverflow.com/questions/17428587/transposing-a-2d-array-in-javascript
    // props.copyDataToClipboard(tableHeaders, tableData);
  };

  return (
    props.data.length > 0 && (
      <LinesChart
        title={props.title}
        data={props.data}
        copyToClipboard={copyToClipboard}
        height={props.height}
        isDarkMode={isDarkMode}
        highlightWeekends={props.highlightWeekends}
        rangeSlider={props.rangeSlider}
      />
    )
  );
};

LinesChartContainer.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      x: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      y: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
      text: PropTypes.arrayOf(PropTypes.string),
      markers: PropTypes.bool,
      lineWidth: PropTypes.number,
      color: PropTypes.shape({
        dark: PropTypes.string,
        light: PropTypes.string,
      }),
    }),
  ).isRequired,
  height: PropTypes.number,
  highlightWeekends: PropTypes.bool,
  rangeSlider: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

LinesChartContainer.defaultProps = {
  title: null,
  height: 300,
  highlightWeekends: false,
  rangeSlider: [],
};

export default LinesChartContainer;
