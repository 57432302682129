const priorYearsDefinitions = {
  CY: {
    color: {
      dark: '#3b93d3',
      light: '#1f76b4',
    },
    deltaYears: 0,
    deltaDays: 0,
  },
  PY: {
    color: {
      dark: '#F29D49',
      light: '#BF7326',
    },
    deltaYears: -1,
    deltaDays: -364,
  },
  'PY*': {
    color: {
      dark: '#F29D49',
      light: '#BF7326',
    },
    deltaYears: -1,
    deltaDays: -364 - 7,
  },
  '2PY': {
    color: {
      dark: '#F55656',
      light: '#C23030',
    },
    deltaYears: -2,
    deltaDays: -364 * 2,
  },
  '3PY': {
    color: {
      dark: '#9179F2',
      light: '#634DBF',
    },
    deltaYears: -3,
    deltaDays: -364 * 3,
  },
  '3PY*': {
    color: {
      dark: '#9179F2',
      light: '#634DBF',
    },
    deltaYears: -3,
    deltaDays: -364 * 3 - 7,
  },
  // '4PY': {
  //   color: {
  //     dark: '#43BF4D',
  //     light: '#238C2C',
  //   },
  //   deltaYears: -4,
  //   deltaDays: -364 * 4,
  // },
  '4PY*': {
    color: {
      dark: '#43BF4D',
      light: '#238C2C',
    },
    deltaYears: -4,
    deltaDays: -364 * 4 - 7,
  },
  // '5PY': {
  //   color: {
  //     dark: '#BFCCD6',
  //     light: '#8A9BA8',
  //   },
  //   deltaYears: -5,
  //   deltaDays: -364 * 5,
  // },
  '5PY*': {
    color: {
      dark: '#BFCCD6',
      light: '#8A9BA8',
    },
    deltaYears: -5,
    deltaDays: -364 * 5 - 7,
  },
  '6PY*': {
    color: {
      dark: '#14CCBD',
      light: '#00B3A4',
    },
    deltaYears: -6,
    deltaDays: -364 * 6 - 7,
  },
};

export default priorYearsDefinitions;
