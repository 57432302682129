/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format, previousSunday, sub } from 'date-fns';
import { Button, H5, Intent } from '@blueprintjs/core';
import MySelect from 'Components/MySelect/MySelect';
import DataContainer from '../Data/DataContainer';
import FiltersContainer from '../Filters/FiltersContainer';
import WeeklyReportTable from './WeeklyReportTable';
import WeeklyEmailReportTable from './WeeklyEmailReportTable';
import OptionsContainer from '../Options/OptionsContainer';
import './weeklyReports.css';

const lastSunday = previousSunday(new Date());
const reportDates = [...Array(20).keys()].map((i) => format(sub(lastSunday, { weeks: i }), 'MM-dd-yyyy'));

const WeeklyReports = (props) => (
  <div className="weeklyReports">
    <div className="topRow">
      <div className="leftGroup">
        <FiltersContainer
          module="weeklyReports"
          dataInfo={props.dataInfo}
          filterValues={Object.keys(props.filterValues).length > 0 ? { Park: props.filterValues.Park } : {}}
          includeDates={false}
        />
        <OptionsContainer module="weeklyReports" subModule="reportTables" show={['priorYears']} />
        <div className="reportDate">
          <h5>Report Date</h5>
          <MySelect
            width={150}
            items={reportDates}
            activeItem={props.filtersSelection.reportDate[0] || format(lastSunday, 'MM-dd-yyyy')}
            handleItemSelect={(newItems) => props.handleUpdateFilter('reportDate', [newItems])}
            filterable={false}
            fill
          />
        </div>
      </div>
      <div className="rightGroup">
        {props.emailsFileQuery.error ? (
          <span>Error getting Emails Data file link</span>
        ) : (
          props.showEmail_DLButton && (
            <a href={props.emailsFileQuery.data}>
              <Button className="downloadEmailFileButton" icon="download">
                Download Emails Data File
              </Button>
            </a>
          )
        )}
        <DataContainer
          module="weeklyReports"
          dataInfo={props.dataInfo}
          refreshData={props.refreshData}
          downloadProgress={props.downloadProgress}
          show_DLButton={props.showGA_DLButton}
        />
      </div>
    </div>
    <H5 style={{ marginTop: '20px' }}>Web Traffic by Channel</H5>
    <WeeklyReportTable weeklyReportData={props.weeklyReportData} />

    <H5 style={{ marginTop: '50px' }}>Email Traffic by Source</H5>
    <WeeklyEmailReportTable weeklyEmailReportData={props.weeklyEmailReportData} />
  </div>
);

WeeklyReports.propTypes = {
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  weeklyReportData: PropTypes.shape({
    CY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
    PY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
  }).isRequired,
  weeklyEmailReportData: PropTypes.shape({
    CY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
    PY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
  filtersSelection: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  emailsFileQuery: PropTypes.shape({
    data: PropTypes.string,
    error: PropTypes.any,
  }).isRequired,
  showGA_DLButton: PropTypes.bool,
  showEmail_DLButton: PropTypes.bool,
};

WeeklyReports.defaultProps = {
  downloadProgress: null,
  showGA_DLButton: false,
  showEmail_DLButton: false,
};

export default WeeklyReports;
