/* eslint-disable no-param-reassign */

import produce from 'immer';

const localState = localStorage.getItem('controlsState');
const loadedState = localState ? JSON.parse(localState) : {};

const controlsReducer = produce((draft = loadedState, action) => {
  if (!draft[action.module]) draft[action.module] = {};
  if (!draft[action.module][action.subModule]) draft[action.module][action.subModule] = {};
  if (!draft[action.module][action.subModule][action.controlName]) draft[action.module][action.subModule][action.controlName] = {};
  switch (action.type) {
    case 'UPDATE_CONTROL_SELECTION':
      draft[action.module][action.subModule][action.controlName].selection = action.values;
      break;
    case 'UPDATE_CONTROL_VALUES':
      draft[action.module][action.subModule][action.controlName].values = action.values;
      break;
    case 'UPDATE_ALL_CONTROL_VALUES':
      for (const [controlName, values] of Object.entries(action.filterValues)) {
        if (!draft[action.module][action.subModule][controlName]) draft[action.module][action.subModule][controlName] = {};
        draft[action.module][action.subModule][controlName].values = values;
      }
      break;
    default:
      break;
  }

  localStorage.setItem('controlsState', JSON.stringify(draft));
  return draft;
});

export default controlsReducer;
