/* eslint-disable no-continue */
import { format, lastDayOfMonth, lastDayOfYear } from 'date-fns';

/** ******************************************************************************
 * Convention: if a date dimension is called 'xyz', the dimensions must be called:
 * - xyzDate
 * - xyzYear
 * - xyzYearMonth
 ****************************************************************************** */

const getFiltersFromControls = (controls, filterDimensions) => {
  const filters = {};
  const dateFilters = {};

  // remove all controls that don't have a selection, or where the selection is [], or where the selection is {from, to} where at least one property is not null
  const controlsWithSelection = Object.fromEntries(
    Object.entries(controls).filter(([k, v]) => v.selection?.length > 0 || v.selection?.from || v.selection?.to),
  );

  for (const controlName of Object.keys(controlsWithSelection)) {
    if (!Object.keys(filterDimensions).includes(controlName)) continue; // exclude controls that are not in the list of filters from the datasetParams
    if (!controlsWithSelection[controlName].selection) continue; // exclude filters that don't have anything selected
    if (controlName.slice(-4) === 'Date') {
      // Date
      if (!!controlsWithSelection[controlName].selection.from && !!controlsWithSelection[controlName].selection.to) {
        // skip when from or to are null
        dateFilters[controlName] = [...(dateFilters[controlName] || []), [controlsWithSelection[controlName].selection]];
      }
    } else if (controlName.slice(-4) === 'Year') {
      // Remove the _Year and _YearMonth filters to replace them with { from, to } filters
      // Year
      const filterName = controlName.replace('Year', 'Date');
      const newSelection = controlsWithSelection[controlName].selection.map((yearFilterValue) => {
        const from = `${yearFilterValue}-01-01`;
        const to = format(lastDayOfYear(new Date(from)), 'yyyy-MM-dd');
        return { from, to };
      });
      dateFilters[filterName] = [...(dateFilters[filterName] || []), newSelection];
    } else if (controlName.slice(-9) === 'YearMonth') {
      // Year-Month
      const filterName = controlName.replace('YearMonth', 'Date');
      const newSelection = controlsWithSelection[controlName].selection.map((yearMonthFilterValue) => {
        const from = `${yearMonthFilterValue}-01`;
        const to = format(lastDayOfMonth(new Date(from)), 'yyyy-MM-dd');
        return { from, to };
      });
      dateFilters[filterName] = [...(dateFilters[filterName] || []), newSelection];
    } else {
      filters[controlName] = controlsWithSelection[controlName].selection;
    }
  }
  return { filters, dateFilters };
};

export default getFiltersFromControls;
